import React, { useState, useEffect } from 'react'
import { Flex } from '../../Components/Common/Flex'
import { Text } from '../../Components/Common/Text'
import { useCQuery } from '../../Services/QueryCache'
import { columnProps } from '../../Services/Functions'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { formatMilliseconds } from './Functions'
import { getNestedValue } from '../LineaOccupata/List'

export const TimeView = ({ conversationId }) => {
  const { data = [], isSuccess } = useCQuery(['time', conversationId])
  const [timeSum, setTimeSum] = useState(0)
  const [timeList, setTimeList] = useState([])
  const [filteredTime, setFilteredTime] = useState(0)

  const [tableState, setTableState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      user: { value: '', matchMode: 'contains' },
      createdAt: { value: '', matchMode: 'contains' },
      endAt: { value: '', matchMode: 'contains' },
      time: { value: '', matchMode: 'contains' }
    }
  })

  useEffect(() => {
    const timeSum = timeList
      ?.filter((item) =>
        Object.keys(tableState.filters).every((key) => {
          const filterValue = tableState?.filters?.[key]?.value
          if (filterValue) {
            const itemValue = getNestedValue(item, key)
            return itemValue?.toLowerCase()?.includes(filterValue?.toLowerCase())
          }
          return true
        }))
      ?.reduce((acc, curr) => {
        return acc + curr.timeNumber
      }, 0)

    setFilteredTime(timeSum)
  }, [timeList?.length, tableState.filters])

  useEffect(() => {
    if (!data?.length) return
    setTimeSum(data.reduce((acc, curr) => acc + curr.time, 0))
    setTimeList(data?.map((time) => ({
      ...time,
      user: time?.user?.username + ((time?.user?.name || time?.user?.surname) ? (' - ' + time?.user?.name + ' ' + time?.user?.surname) : ''),
      createdAt: new Date(time.createdAt).toLocaleString('it-IT'),
      endAt: new Date(time.endAt).toLocaleString('it-IT'),
      timeNumber: time.time,
      time: formatMilliseconds(time.time)
    })))
  }, [data?.length])

  if (!isSuccess) return null
  return (
    <Flex fw>
      <Text value='Resoconto tempo' bold title upCase size={22} style={{ marginBottom: 10 }} />
      <Flex row jb style={{ marginBottom: 10 }}>
        <Text value='Tempo totale:' bold size={18} style={{ marginRight: 10 }} />
        <Text value={formatMilliseconds(timeSum)} size={18} />
      </Flex>
      <Flex row jb style={{ marginBottom: 10 }}>
        <Text value='Tempo totale filtrato:' bold size={18} style={{ marginRight: 10 }} />
        <Text value={formatMilliseconds(filteredTime)} size={18} />
      </Flex>
      <DataTable
        style={{ width: '100%' }} filterDisplay='row' className='TableSignatures'
        value={timeList} emptyMessage='Nessuna attività presente' responsiveLayout='scroll' paginator rows={10}
        first={tableState.first} onPage={setTableState}
        onFilter={(event) => { event.first = 0; setTableState(event) }}
        filters={tableState.filters} totalRecords={timeList.length}
      >
        <Column field='user' {...columnProps('Utente')} />
        <Column field='createdAt' {...columnProps('Data Inizio')} />
        <Column field='endAt' {...columnProps('Data Fine')} />
        <Column field='time' {...columnProps('Tempo')} />
      </DataTable>
    </Flex>
  )
}
