import { useNavigate, useParams } from 'react-router-dom'
import { Button, Flex, Spinner, Text } from '../../Components'
import { saveAttendance, useAttendance } from './Functions'
import { useState, useEffect } from 'react'
import elencoCodiciAttivita from '../../Resources/codiciAttivitaPresenze.json'
import { FloatInput } from '../Gigi/GigiForm/TransferField/InputForm'
import { useAuth } from '../../Provider/AuthProvider'
import { ConfirmModal } from '../../Components/Common/ConfirmModal'

export function Attendance ({ forceAttendanceId, handleNewAttendance }) {
  const { attendanceId } = forceAttendanceId ? { attendanceId: forceAttendanceId } : useParams()
  const navigate = useNavigate()
  const [attendance, setAttendance, isSuccess] = useAttendance(attendanceId)
  const { id = '' } = attendance || {}
  const [hoursManuallyEdited, setHoursManuallyEdited] = useState(false)
  const [checkError, setCheckError] = useState(false)
  const [close, setClose] = useState(false)
  const { user: userObj = {} } = useAuth()
  const { user = {} } = userObj || {}
  const { splitLongActivities } = user || {}
  const [attendanceCreated, setAttendanceCreated] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  const save = async () => {
    setCheckError(true)
    if (!attendance.startDate || !attendance.endDate || !attendance.time || !attendance.reason) {
      // message e return per uscire dalla funzione
      window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Compila i campi mancanti' })
      return
    }
    setCheckError(false)

    const elaboratedAttendance = { ...attendance }
    elaboratedAttendance.startDate = new Date(attendance.startDate)
    elaboratedAttendance.endDate = new Date(attendance.endDate)
    elaboratedAttendance?.endDate?.setDate(elaboratedAttendance.startDate?.getDate())
    elaboratedAttendance?.endDate?.setMonth(elaboratedAttendance.startDate?.getMonth())
    elaboratedAttendance?.endDate?.setFullYear(elaboratedAttendance.startDate?.getFullYear())
    // se l'orario di fine è inferiore a quello di inizio, setto la data di fine al giorno successivo, altrimenti setto la data di fine uguale a quella di inizio
    if (new Date(elaboratedAttendance.endDate).getHours() < new Date(elaboratedAttendance.startDate).getHours() || (new Date(elaboratedAttendance.endDate).getHours() === new Date(elaboratedAttendance.startDate).getHours() && new Date(elaboratedAttendance.endDate).getMinutes() < new Date(elaboratedAttendance.startDate).getMinutes())) {
      const endDate = new Date(elaboratedAttendance.endDate)
      endDate.setDate(endDate.getDate() + 1)
      elaboratedAttendance.endDate = endDate
    } else {
      const endDate = new Date(elaboratedAttendance.endDate)
      endDate.setDate(new Date(elaboratedAttendance.startDate).getDate())
      elaboratedAttendance.endDate = endDate
    }
    if (forceAttendanceId) elaboratedAttendance.fakeActivity = true
    const res = await saveAttendance(elaboratedAttendance)
    handleNewAttendance?.()
    if (!res) return
    res?.id && !forceAttendanceId && navigate('/attendances/list')
    setClose(true)
  }

  const calculateTime = () => {
    const startDate = new Date(attendance.startDate)
    const endDate = new Date(attendance.startDate)
    endDate.setHours(new Date(attendance.endDate).getHours())
    endDate.setMinutes(new Date(attendance.endDate).getMinutes())
    let diff = endDate - startDate
    if (diff < 0) diff += 86400000
    const minutes = Math.floor((diff / 1000) / 60)
    const hours = Math.floor(minutes / 60)
    const minutesLeft = minutes % 60
    setAttendance('time', `${hours.toString().padStart(2, '0')}:${minutesLeft.toString().padStart(2, '0')}`)
  }

  useEffect(() => {
    if (hoursManuallyEdited || id !== 'new') return
    calculateTime()
  }, [attendance?.startDate, attendance?.endDate, id])

  const isNew = id === 'new'
  const showNewAttendance = attendance?.time?.split(':')?.length === 2 &&
    parseInt(attendance?.time?.split(':')?.[0]) > 8 &&
    !forceAttendanceId && splitLongActivities && isNew && !attendanceCreated
  if (!isSuccess || !attendance) return <Spinner />
  if (close) return null
  return (
    <Flex fw>
      {!forceAttendanceId && <Text value={`${isNew ? 'Inserisci' : 'Modifica'} presenza`} title bold size={30} />}
      <Flex fw row wrap style={{ marginTop: 20 }}>
        <ConfirmModal
          header='Attività maggiore di 8 ore'
          label="E' stata inserita un'attività maggiore di 8 ore, procedere lo stesso?"
          onConfirm={save}
          visible={[modalVisible, setModalVisible]}
        />
        <Flex style={{ width: '32%' }}>
          <InputTimeDate
            value={[new Date(attendance.startDate), (date) => setAttendance('startDate', date)]}
            error={checkError && !attendance.startDate}
            label='Data Inizio' labelTime='Ora Inizio'
          />
        </Flex>
        <Flex style={{ width: '32%', margin: '0% 2%' }}>
          <InputTime
            value={[new Date(attendance.endDate), (date) => setAttendance('endDate', date)]}
            error={checkError && !attendance.endDate}
            label='Data Fine' labelTime='Ora Fine'
          />
        </Flex>
        <Flex style={{ width: '32%' }}>
          <FloatInput
            value={[attendance.time, (time) => {
              if (time !== attendance.time) setHoursManuallyEdited(true)
              setAttendance('time', time)
            }]}
            label='Durata' id='time' error={checkError && !attendance.time}
          />
        </Flex>
      </Flex>
      <Flex fw row wrap style={{ marginTop: 10 }}>
        <FloatInput
          type='dropdown' filter
          value={[attendance.reason, (reason) => setAttendance('reason', reason)]}
          onSelect={(reason) => setAttendance('reason', reason)}
          options={elencoCodiciAttivita}
          label='Motivazione' id='reason'
          error={checkError && !attendance.reason}
        />
      </Flex>
      <Flex fw row style={{ marginTop: 20 }}>
        <Button
          label='Salva' icon='fa-duotone fa-floppy-disk' style={{ marginRight: 10 }}
          onClick={() => {
            if (showNewAttendance) setModalVisible(true)
            else save()
          }}
        />
        <Button
          label='Torna indietro' icon='fa-duotone fa-arrow-left'
          onClick={() => navigate('/attendances/list')}
        />
      </Flex>
      {!!showNewAttendance && (
        <Flex fw>
          <hr style={{ width: '100%', marginTop: 20 }} />
          <Text value='La durata inserita è maggiore di 8 ore.' style={{ marginTop: 10 }} size={18} />
          <Text value='Prima di salvare, inserire le ore successive alle prime 8 in una nuova presenza.' style={{ marginTop: 10 }} bold size={18} />
          <Attendance forceAttendanceId='new' handleNewAttendance={() => setAttendanceCreated(true)} />
        </Flex>)}
    </Flex>
  )
}

const pad2 = (n) => n.toString().padStart(2, '0')

export const InputTimeDate = ({ value: [value, setValue], error, style, label, labelTime }) => {
  const [time, setTime] = useState(pad2((value || new Date()).getHours()) + ':' + pad2((value || new Date()).getMinutes()))

  useEffect(() => {
    setTime(pad2((value || new Date()).getHours()) + ':' + pad2((value || new Date()).getMinutes()))
  }, [value])

  return (
    <Flex fw row style={style}>
      <FloatInput
        type='dateObj'
        value={[value, (date) => {
          date.setHours(value.getHours())
          date.setMinutes(value.getMinutes())
          setValue(date)
        }]}
        label={label} id='date' error={error}
      />
      <div style={{ width: 20 }} />
      <FloatInput
        type='time'
        value={[time, (time) => {
          setTime(time)
          if (!time.match(/^\d{2}:\d{2}$/)) return
          const [hours, minutes] = time.split(':')
          const date = new Date(value)
          date.setHours(hours)
          date.setMinutes(minutes)
          setValue(date)
        }]}
        error={error} label={labelTime} id='time'
      />
    </Flex>
  )
}

export const InputTime = ({ value: [value, setValue], error, style, label, labelTime }) => {
  const [time, setTime] = useState(pad2((value || new Date()).getHours()) + ':' + pad2((value || new Date()).getMinutes()))

  useEffect(() => {
    setTime(pad2((value || new Date()).getHours()) + ':' + pad2((value || new Date()).getMinutes()))
  }, [value])

  return (
    <Flex fw row style={style}>
      <FloatInput
        type='time'
        value={[time, (time) => {
          setTime(time)
          if (!time.match(/^\d{2}:\d{2}$/)) return
          const [hours, minutes] = time.split(':')
          const date = new Date(value)
          date.setHours(hours)
          date.setMinutes(minutes)
          setValue(date)
        }]}
        error={error} label={labelTime} id='time'
      />
    </Flex>
  )
}
